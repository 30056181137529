import React, { useState } from 'react';
import { TextField, Button, FormControl, Container, RadioGroup, Radio, FormControlLabel, TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../../components/Auth/firebase';
import { collection, addDoc } from "firebase/firestore";
import { useUser } from '../Dashboard/UserContext';

const accentColor1 = '#92f3a9';
const textColor = '#11506e';

const FounderDetailsForm = () => {
    const navigate = useNavigate();
    const { setUserId } = useUser();
    const [formData, setFormData] = useState({
        companyName: '',
        websiteURL: '',
        companyLogo: null,
        founderName: '',
        founderEmail: '',
        phoneNumber: '',
        shortBio: '',
        investmentPreference: '',
        fundingAmount: '',
        useOfFunds: '',
        userType: 'founder'
    });

    const [formErrors, setFormErrors] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        validateField(name, value);
    };

    const saveToDB = async (form) => {
        try {
            const docRef = await addDoc(collection(db, "users"), form);
            setUserId(docRef.id);
            return docRef.id
        } catch (e) {
            console.error("Error adding document: ", e);
            throw e;
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            companyLogo: file
        });
    };

    const validateField = (name, value) => {
        let errors = { ...formErrors };

        switch (name) {
            case 'websiteURL':
                const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                errors[name] = !urlPattern.test(value) ? 'Please enter a valid URL' : '';
                break;
            case 'founderEmail':
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                errors[name] = !emailPattern.test(value) ? 'Please enter a valid email address' : '';
                break;
            case 'phoneNumber':
                const phonePattern = /^\d{10}$/;
                errors[name] = !phonePattern.test(value) ? 'Please enter a valid phone number' : '';
                break;
            case 'fundingAmount':
                errors[name] = isNaN(value) || value <= 0 ? 'Please enter a positive number' : '';
                break;
            default:
                errors[name] = '';
                break;
        }

        setFormErrors(errors);

        // Check if there are any errors
        const isValid = Object.values(errors).every((error) => error === '');
        setFormIsValid(isValid);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formIsValid) {
            // Handle form submission here
            try {
                const savedUserId = await saveToDB(formData)
                navigate(`/user/dashboard/${savedUserId}`);
            } catch (error) {
                console.error('Error in saving to db: ',error)
            }

        }
    };

    return (
        <Container maxWidth="w-full">
            <div className="flex justify-center">
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4 my-8 w-4/5">
                    <h2 className="text-lg font-semibold">Company Details</h2>
                    <div className='flex justify-between'>
                        <TextField
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            label="Company Name"
                            placeholder="Enter company name"
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            type="text"
                            name="websiteURL"
                            value={formData.websiteURL}
                            label="Website URL"
                            placeholder="Enter website URL"
                            onChange={handleInputChange}
                            required
                        />
                        {/* <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="mt-2"
                            required
                        /> */}
                        <TextField
                            type="text"
                            name="companyLogo"
                            value={formData.companyLogo}
                            label="Company Logo"
                            placeholder="Company logo URL"
                            onChange={handleInputChange}
                            required
                        />
                        {formErrors.websiteURL && <span className="text-red-500">{formErrors.websiteURL}</span>}
                    </div>

                    <h2 className="text-lg font-semibold">Founder Information</h2>
                    <div className='flex flex-col justify-between w-1/2'>
                        <TextField
                            type="text"
                            name="founderName"
                            style={{ marginTop: '25px' }}
                            value={formData.founderName}
                            label="Name"
                            placeholder="Enter founder name"
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            type="email"
                            name="founderEmail"
                            style={{ marginTop: '25px' }}
                            value={formData.founderEmail}
                            label="Email"
                            placeholder="Enter email"
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            style={{ marginTop: '25px' }}
                            label="Phone Number"
                            placeholder="Enter phone number"
                            onChange={handleInputChange}
                            required
                        />
                        <TextareaAutosize
                            name="shortBio"
                            value={formData.shortBio}
                            placeholder="Enter short bio"
                            style={{ marginTop: '25px' }}
                            onChange={handleInputChange}
                            required
                            className="mt-2 p-2 w-full border rounded"
                        />
                        {formErrors.founderEmail && <span className="text-red-500">{formErrors.founderEmail}</span>}
                        {formErrors.phoneNumber && <span className="text-red-500">{formErrors.phoneNumber}</span>}
                    </div>

                    <h2 className="text-lg font-semibold">Funding Requirements</h2>
                    <div className='flex flex-col justify-between'>
                        <TextField
                            type="number"
                            name="fundingAmount"
                            style={{ marginTop: '25px' }}
                            value={formData.fundingAmount}
                            label="Funding Amount"
                            placeholder="Enter funding amount"
                            onChange={handleInputChange}
                            required
                        />
                        <TextareaAutosize
                            name="useOfFunds"
                            value={formData.useOfFunds}
                            style={{ marginTop: '25px' }}
                            placeholder="Briefly explain use of funds"
                            onChange={handleInputChange}
                            required
                            className="mt-2 p-2 w-full border rounded"
                        />
                        {formErrors.fundingAmount && <span className="text-red-500">{formErrors.fundingAmount}</span>}
                    </div>
                    <div className='flex justify-center'>
                        <Button type="submit" style={{ marginTop: '25px' }} disabled={!formIsValid} variant="contained" color="primary">Submit</Button>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default FounderDetailsForm;