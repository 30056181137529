import React, { useState, useEffect } from 'react';
import { db } from '../../components/Auth/firebase';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import LeftSidebar from '../Dashboard/LeftSidebar/LeftSidebar';
import RightSidebar from '../Dashboard/RightSidebar/RightSidebar';
import { Container, Avatar, Typography, CircularProgress, Grid, Paper, Box, Divider } from '@mui/material';

const ProfilePage = () => {
    const { userId } = useParams();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const docRef = doc(db, 'users', userId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists) {
                    setProfile(docSnap.data());
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [userId]);

    return (
        <div className="flex h-screen">
            <div className="w-1/5">
                <LeftSidebar />
            </div>
            <div className="flex-1 overflow-y-auto bg-gray-100 p-4">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <CircularProgress />
                    </div>
                ) : profile ? (
                    <Container maxWidth="md">
                        <Paper elevation={3} className="p-6">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <Avatar
                                        alt={profile.companyLogo}
                                        src={profile.profilePicture}
                                        className="w-32 h-32 mx-auto"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="h5" className="font-bold text-gray-700">
                                        {profile.founderName}
                                    </Typography>
                                    <Typography variant="subtitle1" className="text-gray-500">
                                        {profile.companyName}
                                    </Typography>
                                    <Typography variant="body1" className="text-gray-500">
                                        <a href={profile.websiteURL} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                            {profile.websiteURL}
                                        </a>
                                    </Typography>
                                    <Divider className="my-4" />
                                    <Typography variant="body2" className="text-gray-600">
                                        <strong>Bio:</strong> {profile.shortBio}
                                    </Typography>
                                    <Typography variant="body2" className="text-gray-600 mt-2">
                                        <strong>Funding requirements:</strong> {profile.fundingAmount}
                                    </Typography>
                                    <Typography variant="body2" className="text-gray-600 mt-2">
                                        <strong>Use of funds:</strong> {profile.useOfFunds}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                ) : (
                    <div className="flex justify-center items-center h-full">
                        <Typography variant="h6" className="text-gray-500">
                            Profile not found
                        </Typography>
                    </div>
                )}
            </div>
            <div className="w-1/5">
                <RightSidebar />
            </div>
        </div>
    );
};

export default ProfilePage;