// Navigation.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PortfolioIcon from '@mui/icons-material/Work';
import DealsIcon from '@mui/icons-material/LocalOffer';
import MessagesIcon from '@mui/icons-material/Message';
import NotificationIcon from '@mui/icons-material/Notifications';
import ProfileIcon from '@mui/icons-material/Person';
import ContactIcon from '@mui/icons-material/ContactMail';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useUser } from '../UserContext';

const navItemsTop = [
    { label: 'Home', icon: <HomeIcon />, route: '/user/dashboard/:userId' },
    { label: 'My Portfolio', icon: <PortfolioIcon />, route: '/user/portfolio/:userId' },
    { label: 'Deals', icon: <DealsIcon />, route: '/user/deals/:userId' },
    { label: 'Messages', icon: <MessagesIcon />, route: '/user/messages/:userId' },
    { label: 'Notification', icon: <NotificationIcon />, route: '/user/notifications/:userId' },
];

const navItemsBottom = [
    { label: 'Profile', icon: <ProfileIcon />, route: '/user/profile/:userId' },
    { label: 'Contact Us', icon: <ContactIcon />, route: '/user/contact/:userId' },
    { label: 'More', icon: <MoreIcon />, route: '/user/more/:userId' },
];

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = useUser();

    const isActive = (route) => {
        const adjustedRoute = route.replace(':userId', userId);
        return location.pathname === adjustedRoute;
    };

    return (
        <div className="bg-gray-200 h-screen mx-auto flex flex-col justify-between">
            <div className="p-4 border-b">
                {navItemsTop.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => navigate(item.route.replace(':userId', userId))}
                        className={`flex items-center cursor-pointer mb-2 p-2 rounded ${isActive(item.route) ? 'bg-green-200' : ''}`}
                    >
                        {item.icon}
                        <span className="ml-2">{item.label}</span>
                    </div>
                ))}
            </div>
            <div className="p-4">
                {navItemsBottom.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => navigate(item.route.replace(':userId', userId))}
                        className={`flex items-center cursor-pointer mb-2 p-2 rounded ${isActive(item.route) ? 'bg-green-200' : ''}`}
                    >
                        {item.icon}
                        <span className="ml-2">{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Navigation;