import React from 'react';
import { Link } from 'react-router-dom';

function LoginHome() {
    return (
        React.createElement('div', { className: 'flex flex-col mt-24' },
            // Hero Section
            React.createElement('div', { className: 'bg-cover bg-center h-44 flex items-center justify-center' },
                React.createElement('div', { className: 'text-center' },
                    React.createElement('h1', { className: 'text-4xl font-bold mb-4' }, 'Your Gateway to Investment Success'),
                    React.createElement('p', { className: 'text-lg' }, 'Empowering Investors and Startups to Thrive Together')
                )
            ),
            React.createElement('div', { className: 'py-6 flex justify-around' },

                // Value Proposition for Investors
                React.createElement('div', { className: 'bg-gray-100 py-12 rounded-2xl' },
                    React.createElement('div', { className: 'container mx-auto p-10' },
                        React.createElement('h2', { className: 'text-2xl font-bold text-center mb-8' }, 'For Investors'),
                        React.createElement('p', { className: 'text-lg text-center' }, 'Discover promising startups and invest in the future.')
                    )
                ),

                // Value Proposition for Startups
                React.createElement('div', { className: 'bg-gray-200 py-12 rounded-2xl' },
                    React.createElement('div', { className: 'container mx-auto p-10' },
                        React.createElement('h2', { className: 'text-2xl font-bold text-center mb-8' }, 'For Startups'),
                        React.createElement('p', { className: 'text-lg text-center' }, 'Find the support and funding you need to grow your business.')
                    )
                ),
            ),

            // Call-to-Action
            React.createElement('div', { className: 'py-2 flex justify-center' },
                React.createElement('div', { className: 'text-center' },
                    React.createElement('h2', { className: 'text-2xl font-bold mb-4' }, 'Ready to Get Started?'),
                    React.createElement('div', { className: 'flex justify-center' },
                        React.createElement(Link, { to: '/signup', state: { userType: 'investor' } },
                            React.createElement('button', { className: 'bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full mr-4' }, 'Join as Investor'),
                        ),
                        React.createElement(Link, { to: '/signup', state: { userType: 'founder' } },
                            React.createElement('button', { className: 'bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full' }, 'Join as Startup')
                        )
                    )
                )
            )
        )
    );
}

export default LoginHome;