// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD3U5NxV6XzEWqJvnN1EyhAdg3DO8SQwmQ",
  authDomain: "startuphub-ee188.firebaseapp.com",
  projectId: "startuphub-ee188",
  storageBucket: "startuphub-ee188.appspot.com",
  messagingSenderId: "116887948214",
  appId: "1:116887948214:web:4120e6e4ac0e2680edcf17",
  measurementId: "G-VN4J2V72K6"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };