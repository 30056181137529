import * as React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/authContexts'
import { doSignOut } from '../Auth/auth';
import { useState } from 'react';

// Colors from provided information
const primaryColor = '#0a3042';
const secondaryColor = '#ffffff';
const accentColor1 = '#92f3a9';
const textColor = '#11506e';
const accentColor2 = '#36e961';

// Styled component for custom logo
const StyledLogo = styled('img')({
  width: "200px",
  objectFit: 'contain',
  paddingTop: "5px"
});

const Header = () => {

  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <AppBar sx={{ backgroundColor: primaryColor }} className="h-16 bg-blue-500 text-white flex items-center justify-center">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Left section with company logo */}
        <StyledLogo src={require('./Logo.png')} alt="Logo" />

        {/* Middle section with buttons */}
        {/* Navigation (desktop view) */}
        <nav className="hidden sm:flex space-x-4">
          <Typography  variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
            <Button href="/" color="inherit">Home</Button>
          </Typography>
          <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
            <Button href="/about" color="inherit">About us</Button>
          </Typography>
          <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
            <Button href="/founderDetailsForm" color="inherit">For Founders</Button>
          </Typography>
          <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
            <Button href="/investorDetailsForm" color="inherit">For Investors</Button>
          </Typography>
          {/* Right section with login and sign up buttons */}
          {
            userLoggedIn
              ?
              <div className="flex space-x-2 sm:flex-grow">
                <Button onClick={() => { doSignOut().then(() => { navigate('/') }) }} style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }}>Logout</Button>
              </div>
              :
              <div className="flex space-x-2 sm:flex-grow">
                <Button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none" style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }} href="/signin">Login</Button>
                {/* <Button className="px-4 py-2 bg-transparent border border-white rounded-md text-white hover:bg-white/20 focus:outline-none" style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }} href="/signup">Sign Up</Button> */}
              </div>
          }
        </nav>

        {/* Mobile menu button */}
        <button id="menu-btn" className="sm:hidden block focus:outline-none" onClick={toggleMobileMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16v12H4zm2-2a2 2 0 1 0-4 0 2 2 0 0 0 4 0zM16 14a2 2 0 1 0-4 0 2 2 0 0 0 4 0z" />
          </svg>
        </button>

        {/* Mobile menu (optional) */}
        <nav className={`fixed top-0 left-0 h-full w-full overflow-y-auto bg-gray-900 opacity-75 transition-all duration-300 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} sm:hidden`}>
          <div className="flex flex-col items-center pt-16 px-6">
            <ul className="space-y-4">
              <li>
                <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
                  <Button href="/" color="inherit">Home</Button>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
                  <Button href="/about" color="inherit">About us</Button>
                </Typography>
              </li>
              <li>
                <Typography href="/founderDetailsForm" variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
                  <Button href="/founderDetailsForm" color="inherit">For Founders</Button>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" noWrap component="div" sx={{ color: secondaryColor, marginLeft: 10 }} className="text-lg hover:underline">
                  <Button href="/investorDetailsForm" color="inherit">For Investors</Button>
                </Typography>
              </li>
            </ul>
            {/* Right section with login and sign up buttons */}
            {
              userLoggedIn
                ?
                <div className="mt-4 flex justify-center space-x-4">
                  <Button onClick={() => { doSignOut().then(() => { navigate('/') }) }} style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }}>Logout</Button>
                </div>
                :
                <div className="mt-4 flex justify-center space-x-4">
                  <Button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none" style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }} href="/signin">Login</Button>
                  {/* <Button className="px-4 py-2 bg-transparent border border-white rounded-md text-white hover:bg-white/20 focus:outline-none" style={{ color: `${accentColor1}`, backgroundColor: `${textColor}` }} href="/signup">Sign Up</Button> */}
                </div>
            }
            <button className="mt-auto text-xl text-white focus:outline-none" onClick={toggleMobileMenu}>
              Close
            </button>
          </div>
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
