// RightSidebar.js

import React from 'react';
import Avatar from '@mui/material/Avatar';

const RightSidebar = () => {
    return (
        <div className="bg-white p-4 h-screen rounded shadow-md">
            {/* Search Box */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search..."
                    className="border p-2 w-full rounded"
                />
            </div>

            {/* Filter Section */}
            <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Apply Filters</h3>
                <div className="flex flex-wrap">
                    {['Fin Tech', 'Social', 'Ed Tech', 'AI', 'SAAS', 'Healthcare', 'eCommerce', 'BioTech'].map((filter, index) => (
                        <button key={index} className="mr-2 mb-2 bg-gray-200 p-2 rounded">{filter}</button>
                    ))}
                </div>
            </div>

            {/* Follow Us */}
            <div className="mb-6">
                <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
                <div className="flex space-x-4">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <Avatar alt="Facebook" src="/path/to/facebook-logo.png" />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <Avatar alt="Twitter" src="/path/to/twitter-logo.png" />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <Avatar alt="Instagram" src="/path/to/instagram-logo.png" />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <Avatar alt="LinkedIn" src="/path/to/linkedin-logo.png" />
                    </a>
                </div>
            </div>

            {/* Footer Nav */}
            <div className="mb-6">
                <ul className="flex flex-wrap space-x-4 mb-2">
                    <li><a href="#" className="text-blue-500">About</a></li>
                    <li><a href="#" className="text-blue-500">Privacy & Policy</a></li>
                    <li><a href="#" className="text-blue-500">Help Centre</a></li>
                </ul>
                <ul className="flex flex-wrap space-x-4">
                    <li><a href="#" className="text-blue-500">Get the App</a></li>
                    <li><a href="#" className="text-blue-500">Accessibility</a></li>
                    <li><a href="#" className="text-blue-500">Ad Choices</a></li>
                    <li><a href="#" className="text-blue-500">More</a></li>
                </ul>
            </div>

            {/* Copyright */}
            <div className="text-center text-gray-500 mt-4">
                <p>&copy; 2024 startuphub.space</p>
            </div>
        </div>
    );
};

export default RightSidebar;
