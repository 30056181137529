import React from 'react';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import RightSidebar from './RightSidebar/RightSidebar';
import MiddleSectionFeeds from './MiddleSectionFeeds/MiddleSectionFeeds';

const Dashboard = () => {
    return (
        <div className="flex h-screen">
            <div className="w-1/5">
                <LeftSidebar />
            </div>
            <div className="w-3/5 overflow-y-auto">
                <MiddleSectionFeeds />
            </div>
            <div className="w-1/5">
                <RightSidebar />
            </div>
        </div>
    );
};

export default Dashboard;
