// InvestorDetailsForm.js
import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, MenuItem, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const accentColor1 = '#92f3a9';
const textColor = '#11506e';

const options = [
    'Angel Investor',
    'Venture Capitalist',
    'Private Equity Investor',
    'Other'
];

const investmentStages = [
    'Ideation',
    'Proof of Concept',
    'Beta Launched',
    'Early Revenues',
    'Steady Revenues',
    'Stage doesn\'t matter'
];

const InvestorDetailsForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: '',
        linkedinUrl: '',
        firstName: '',
        mobileNumber: '',
        highNetWorth: false,
        investmentExperience: '',
        country: '',
        location: '',
        websiteUrl: '',
        summary: '',
        alternateMobileNumber: '',
        verticalSector: '',
        horizontalSector: '',
        investmentStages: [],
        userType: 'investor'
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setFormData({
                ...formData,
                investmentStages: [...formData.investmentStages, name]
            });
        } else {
            setFormData({
                ...formData,
                investmentStages: formData.investmentStages.filter(stage => stage !== name)
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add validation logic here
        console.log(formData);
        navigate('/dashboard');
    };

    return (
        <Container maxWidth="w-full">
            <form onSubmit={handleSubmit} className="space-y-4 my-8">
                <div className='flex justify-evenly'>
                    {/* Personal Details Section */}
                    <section className='w-2/5'>
                        <h2 className="text-xl font-semibold mb-2">Personal Details</h2>
                        <TextField
                            label="Full Name"
                            name="fullName"
                            style={{ marginTop: '25px' }}
                            value={formData.fullName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Linkedin URL"
                            name="linkedinUrl"
                            style={{ marginTop: '25px' }}
                            value={formData.linkedinUrl}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Mobile Number"
                            name="mobileNumber"
                            style={{ marginTop: '25px' }}
                            value={formData.mobileNumber}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="highNetWorth"
                                    checked={formData.highNetWorth}
                                    onChange={handleChange}
                                />
                            }
                            label="High Net Worth Individual"
                            style={{ marginTop: '25px' }}
                        />
                        <TextField
                            select
                            label="Investment Experience"
                            name="investmentExperience"
                            style={{ marginTop: '25px' }}
                            value={formData.investmentExperience}
                            onChange={handleChange}
                            fullWidth
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Country of Citizenship"
                            name="country"
                            style={{ marginTop: '25px' }}
                            value={formData.country}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Location"
                            name="location"
                            style={{ marginTop: '25px' }}
                            value={formData.location}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Website URL"
                            name="websiteUrl"
                            style={{ marginTop: '25px' }}
                            value={formData.websiteUrl}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Summary"
                            name="summary"
                            style={{ marginTop: '25px' }}
                            value={formData.summary}
                            onChange={handleChange}
                            multiline
                            fullWidth
                        />
                        <TextField
                            label="Alternate Mobile Number"
                            name="alternateMobileNumber"
                            style={{ marginTop: '25px' }}
                            value={formData.alternateMobileNumber}
                            onChange={handleChange}
                            fullWidth
                        />

                    </section>

                    {/* Investment Preferences Section */}
                    <section className='w-2/5'>
                        <h2 className="text-xl font-semibold mb-2">Investment Preferences</h2>
                        <TextField
                            select
                            label="Vertical Sector"
                            name="verticalSector"
                            style={{ marginTop: '25px' }}
                            value={formData.verticalSector}
                            onChange={handleChange}
                            fullWidth
                        >
                            {/* Options for vertical sectors */}
                        </TextField>
                        <TextField
                            select
                            label="Horizontal Sector"
                            name="horizontalSector"
                            style={{ marginTop: '25px' }}
                            value={formData.horizontalSector}
                            onChange={handleChange}
                            fullWidth
                        >
                            {/* Options for horizontal sectors */}
                        </TextField>
                        <div>
                            <h3 style={{ marginTop: '25px' }} className="text-lg font-semibold">Startup Stages Preference</h3>
                            {investmentStages.map(stage => (
                                <FormControlLabel
                                    key={stage}
                                    style={{ marginTop: '25px' }}
                                    control={
                                        <Checkbox
                                            name={stage}
                                            checked={formData.investmentStages.includes(stage)}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label={stage}
                                />
                            ))}
                        </div>
                    </section>
                </div>
                <div className='flex justify-evenly'>
                    <Button variant="contained" type="submit" style={{ marginTop: '25px', color: `${accentColor1}`, backgroundColor: `${textColor}` }}>
                        Submit
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default InvestorDetailsForm;