// MiddleSectionFeeds.js

import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import LinearProgress from '@mui/material/LinearProgress';
const MiddleSectionFeeds = () => {
    return (
        <div className="container mx-auto p-4">
            {/* Feed Cards */}
            <div className="space-y-8">
                {/* Feed 1 */}
                <div className="bg-white p-4 rounded shadow-md w-full h-2/3">
                    {/* Header */}
                    <div className="bg-white p-4 flex justify-between items-center">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <Avatar alt="Profile Picture" src="/path/to/profile.jpg" className="mr-2" />
                            <div>
                                <h2 className="text-lg font-semibold">John Doe</h2>
                                <p className="text-sm text-gray-500">New York, NY</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center">
                            <div className="mr-4">
                                <p className="text-lg font-semibold">ASK: 50 Lakhs for 2% equity</p>
                                <LinearProgress
                                    variant="determinate"
                                    value={25}
                                    sx={{
                                        borderRadius: '9999px',
                                        height: '10px',
                                        marginTop: '4px',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'rgb(146, 243, 169)',
                                        },
                                        '& .MuiLinearProgress-root': {
                                            backgroundColor: 'rgb(17, 80, 110)',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Content */}
                    <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit....</p>
                    <img src={require('./feeds-test.jpg')} alt="Feed 1" className="mb-4 rounded w-full" />
                    {/* Footer */}
                    <div className="flex justify-between">
                        <div>
                            <IconButton aria-label="Like">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="Save for later">
                                <BookmarkIcon />
                            </IconButton>
                        </div>
                        <button className="bg-green-300 px-4 py-2 rounded">Invest</button>
                    </div>
                </div>

                {/* Feed 2 */}
                <div className="bg-white p-4 rounded shadow-md w-full h-2/3">
                    {/* Header */}
                    <div className="bg-white p-4 flex justify-between items-center">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <Avatar alt="Profile Picture" src="/path/to/profile.jpg" className="mr-2" />
                            <div>
                                <h2 className="text-lg font-semibold">John Doe</h2>
                                <p className="text-sm text-gray-500">New York, NY</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center">
                            <div className="mr-4">
                                <p className="text-lg font-semibold">ASK: 50 Lakhs for 2% equity</p>
                                <LinearProgress
                                    variant="determinate"
                                    value={25}
                                    sx={{
                                        borderRadius: '9999px',
                                        height: '10px',
                                        marginTop: '4px',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'rgb(146, 243, 169)',
                                        },
                                        '& .MuiLinearProgress-root': {
                                            backgroundColor: 'rgb(17, 80, 110)',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Content */}
                    <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit....</p>
                    <img src={require('./feeds-test.jpg')} alt="Feed 2" className="mb-4 rounded w-full" />
                    {/* Footer */}
                    <div className="flex justify-between">
                        <div>
                            <IconButton aria-label="Like">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="Save for later">
                                <BookmarkIcon />
                            </IconButton>
                        </div>
                        <button className="bg-green-300 px-4 py-2 rounded">Invest</button>
                    </div>
                </div>

                {/* Feed 3 */}
                <div className="bg-white p-4 rounded shadow-md w-full h-2/3">
                    {/* Header */}
                    <div className="bg-white p-4 flex justify-between items-center">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <Avatar alt="Profile Picture" src="/path/to/profile.jpg" className="mr-2" />
                            <div>
                                <h2 className="text-lg font-semibold">John Doe</h2>
                                <p className="text-sm text-gray-500">New York, NY</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center">
                            <div className="mr-4">
                                <p className="text-lg font-semibold">ASK: 50 Lakhs for 2% equity</p>
                                <LinearProgress
                                    variant="determinate"
                                    value={25}
                                    sx={{
                                        borderRadius: '9999px',
                                        height: '10px',
                                        marginTop: '4px',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'rgb(146, 243, 169)',
                                        },
                                        '& .MuiLinearProgress-root': {
                                            backgroundColor: 'rgb(17, 80, 110)',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Content */}
                    <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit....</p>
                    <img src={require('./feeds-test.jpg')} alt="Feed 3" className="mb-4 rounded w-full" />
                    {/* Footer */}
                    <div className="flex justify-between">
                        <div>
                            <IconButton aria-label="Like">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="Save for later">
                                <BookmarkIcon />
                            </IconButton>
                        </div>
                        <button className="bg-green-300 px-4 py-2 rounded">Invest</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiddleSectionFeeds;
