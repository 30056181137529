import React from 'react';
import LeftSidebar from '../Dashboard/LeftSidebar/LeftSidebar';
import RightSidebar from '../Dashboard/RightSidebar/RightSidebar';

const Messages = () => {
    return (
        <div className="flex h-screen">
            <div className="w-1/5">
                <LeftSidebar />
            </div>
            <div className="w-3/5 overflow-y-auto">
                404
            </div>
            <div className="w-1/5">
                <RightSidebar />
            </div>
        </div>
    );
};

export default Messages;