import React from 'react';

const AboutPage = () => {
    return (
        <div className="container mx-auto py-8">
            <h1 className="text-3xl font-semibold mb-4">About Startuphub.space</h1>
            <p className="mb-4">
                Startuphub.space is a platform dedicated to connecting investors with company founders.
                Our mission is to facilitate investment opportunities for startups while providing investors
                with access to promising ventures.
            </p>
            <h2 className="text-xl font-semibold mb-2">Key Features</h2>
            <ul className="list-disc list-inside mb-4">
                <li>Founder Profiles and Company Posts</li>
                <li>Investment Opportunities for Investors</li>
                <li>Real-time Notifications</li>
                <li>User Authentication and Authorization</li>
                {/* Add more key features */}
            </ul>
            <h2 className="text-xl font-semibold mb-2">Value Proposition</h2>
            <p className="mb-4">
                Startuphub.space provides founders with a platform to showcase their companies and products,
                attract investment, and grow their businesses. For investors, it offers access to a diverse
                range of investment opportunities and potential high returns.
            </p>
            <h2 className="text-xl font-semibold mb-2">How It Works</h2>
            <p className="mb-4">
                Founders can sign up, create profiles for their companies, and post about their products and
                services. Investors can browse through company profiles, review posts, and make investments
                directly through the platform.
            </p>
            <h2 className="text-xl font-semibold mb-2">Team</h2>
            <p className="mb-4">
                The Startuphub.space team is comprised of experienced professionals dedicated to supporting
                startup growth and fostering investment opportunities.
            </p>
            {/* Add team member bios */}
            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p>
                For inquiries and support, please contact us at <a href="mailto:info@startuphub.space">info@startuphub.space</a>.
            </p>
        </div>
    );
};

export default AboutPage;