import React from 'react';
import { AuthProvider } from "./contexts/authContexts";
import { useRoutes } from "react-router-dom";

import Header from "./components/Header/Header";
import Register from './components/Auth/Register'; // Import your SignUp component
import Login from './components/Auth/Login'; // Import your SignIn component
import Hero from './components/Hero/Hero';
import Home from './components/Home/Home';
import LoginHome from './components/Login/LoginHome';
import InvertorDetailsForm from './components/Login/InvestorDetailsForm';
import FounderDetailsForm from './components/Login/FounderDetailsForm';
import Dashboard from './components/Dashboard/Dashboard';
import AboutPage from './components/About/About';
import ProfilePage from './components/Login/ProfilePage';
import ProfileForm from './components/Login/ProfileForm';
import { UserProvider } from './components/Dashboard/UserContext';
import Portfolio from './components/Dashboard/Portfolio';
import Messages from './components/Dashboard/Messages';
import Deals from './components/Dashboard/Deals';
import Notifications from './components/Dashboard/Notifications';
import ContactUs from './components/Dashboard/Contactus';

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Hero />,
    },
    {
      path: "/",
      element: <LoginHome />,
    },
    {
      path: "/signin",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Register />,
    },
    {
      path: "/investorDetailsForm",
      element: <InvertorDetailsForm />,
    },
    {
      path: "/founderDetailsForm",
      element: <FounderDetailsForm />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "user/dashboard/:userId",
      element: <Dashboard />
    },
    {
      path: "/about",
      element: <AboutPage />
    },
    {
      path: "/user/profile/:userId",
      element: <ProfilePage />
    },
    {
      path: "/user/createProfile",
      element: <ProfileForm />
    },
    {
      path: "/user/portfolio/:userId",
      element: <Portfolio />
    },
    {
      path: "/user/messages/:userId",
      element: <Messages />
    },
    {
      path: "/user/deals/:userId",
      element: <Deals />
    },
    {
      path: "/user/notifications/:userId",
      element: <Notifications />
    },
    {
      path: "/user/contact/:userId",
      element: <ContactUs />
    }
  ];

  let routesElement = useRoutes(routesArray);

  return (
    <div className="App relative">

      <AuthProvider>
      <UserProvider>
        <div className="relative h-screen">

          <div className="absolute inset-x-0 top-0 h-16">
            <Header />
          </div>
          <div className="absolute inset-x-0 top-16 bottom-0 overflow-y-auto">{routesElement}</div>
        </div>
        </UserProvider>
      </AuthProvider>
      {/* Rest of your application's content */}
    </div>
  );
}

export default App;
